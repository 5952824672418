import styled from "styled-components";


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  max-width: 998px;
  padding: 21px 32px;
`;
