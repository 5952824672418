import {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import classNames from "classnames";
import LogoImage from "assets/full_logo.png";
import {RiInstallLine} from "react-icons/ri";

export function Layout() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();

            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("사용자가 설치 프롬프트에 동의했습니다.");
                } else {
                    console.log("사용자가 설치 프롬프트를 무시했습니다.");
                }

                setDeferredPrompt(null);
            });
        }
    };

    const handleBeforeInstallPrompt = (event) => {
        event.preventDefault();
        setDeferredPrompt(event);
    };

    useEffect(() => {
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);

    const VersionInformation = () => (
        <p className={classNames("font-italic", "text-center", "mt-5")}>Current Version : {process.env.NODE_ENV} - {process.env.REACT_APP_VERSION}</p>
    );

    const ProviderInformation = () => (
        <div className={classNames("d-flex", "flex-row", "justify-content-center", "align-items-center", "text-right", "gap-1", "py-3")}>
            <b>Powered by</b>
            <img src={LogoImage} width={150} alt={"Test Qube Logo"} />
        </div>
    );

    return (
        <div className={"d-flex flex-column justify-content-center align-items-center w-100 h-100 bg-main"}>
            <div className={classNames("inner-wrapper")} style={{ scrollbarWidth: "none" }}>
                <div className={"page-content"}>
                    <Outlet />
                </div>
                <VersionInformation/>
                <ProviderInformation/>
            </div>
            {deferredPrompt && (
                <div className={"d-flex flex-column justify-content-center align-items-center"} style={{ position: "absolute", right: 0, top: 0, padding: 18, margin: 18, background: "white", border: "1px solid #EFEFEF", borderRadius: 18 }}>
                    <h3>Install Desktop App</h3>
                    <button className={"btn-primary mt-3 w-100"} onClick={handleInstallClick}><RiInstallLine /> Add to Desktop</button>
                </div>
            )}
        </div>
    )
}
