import {useEffect} from "react";
import {Navigate, useLocation} from "react-router-dom";
import { useAuth } from "hooks";

export const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const {user, loaded, login}= useAuth();

    useEffect(() => {
        (async () => {
            if (!loaded)
                await login();
        })();
    }, [loaded, login]);

    if (loaded && !user) {
        // user is not authenticated
        return <Navigate to={`/sign_in?next=${location.pathname}`} />;
    }
    if (loaded && user)
        return children;
};
