import {createContext, useContext, useState} from "react";
import {requestMyInformation, requestLogout} from "apis";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(false);

    // call this function when you want to authenticate the user
    const login = async () => {
        try {
            const user = await requestMyInformation();
            setUser(user);
        } finally {
            setLoaded(true);
        }
    };

    // call this function to sign out logged in user
    const logout = async () => {
        try {
            await requestLogout();
        } finally {
            setUser(null);
            setLoaded(false);
        }
    };

    return (
        <AuthContext.Provider value={{user, loaded, login, logout}}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
