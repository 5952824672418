import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {Title} from "styles/texts";

export function Panel({ mainImage, title, to, href, ...rest }) {
    const navigate = useNavigate();

    return (
        <>
            {to && (
            <Wrapper {...rest} onClick={() => navigate(to, { replace: true })}>
                {mainImage && <MainImage src={mainImage} />}
                <Title>{title}</Title>
            </Wrapper>
            )}
            {href && (
            <WrapperA {...rest} href={href} target={"_blank"} refer={"noreferrer"}>
                {mainImage && <MainImage src={mainImage} />}
                <Title>{title}</Title>
            </WrapperA>
            )}
        </>
    )
}

const MainImage = styled.img`
  width: auto;
  height: 64px;
  margin-bottom: 14px;
`;

const Wrapper = styled.button`
  border: 1px solid #DADADA;
  padding: 23px 72px;
  border-radius: 8px;
  box-shadow: 5px 7px 17px rgba(0, 0, 0, 0.24);
  background-color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
`;

const WrapperA = styled.a`
  border: 1px solid #DADADA;
  padding: 23px 72px;
  border-radius: 8px;
  box-shadow: 5px 7px 17px rgba(0, 0, 0, 0.24);
  background-color: white;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
`;
