import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

export function initAxiosAuth(token) {
    axios.defaults.headers['Authorization'] = `Token ${token}`;
}

export function initAxiosIdentity(identity) {
    axios.defaults.headers['Identity'] = identity;
}

axios.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (error) => {
        if (!error.response) {
            console.error("Server Error: 500");
        } else if (error.response.status === 403) {
            console.error("403 Error: Forbidden");
        } else if (error.response.status === 401) {
            console.error("401 Error : Unauthorized");
        }
        // alert(`(${error.response.status}) ${Object.keys(error.response.data).map((k) => error.response.data[k])}`);
        return Promise.reject(error);
    }
);
