import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTimer} from "hooks";
import {Wrapper as BaseWrapper} from "styles/layouts";
import styled from "styled-components";
import {Header} from "styles/texts";
import {Button} from "styles/buttons";
import {Timer as BaseTimer} from "components";

export function TestRest() {
    const { uid } = useParams();
    const navigate = useNavigate();
    const { setStartTime, setTimerOnState } = useTimer();

    useEffect(() => {
        setStartTime(10 * 60 * 1000);
        setTimerOnState(true);
    }, []);

    // useEffect(() => {
    //     if (startRest && !timerOnState)
    //         navigate(`/test_sheets/${uid}`, { replace: true })
    // }, [timerOnState]);

    return (
        <Wrapper>
            <InnerWrapper>
                <TimeWrapper>
                    <TimeTitle>Break Time</TimeTitle>
                    <Timer minutes={10} />
                    <NextButton onClick={() => {
                        setTimerOnState(false);
                        navigate(`/test_center/tests/${uid}/loading`, { replace: true });
                    }}>Resume Test</NextButton>
                </TimeWrapper>
                <Splitter />
                <DescriptionWrpaper>
                    <DescriptionInnerWrpaper>
                    <TimeTitle>
                        <p>Practice Test Chill Time</p>
                        <br/>
                        <p>Just gonna park this practice test right here for a sec. We'll get back to it when you're all set and good to go. On the actual test day, you'll need to wait for the timer to tick down. Here's how it goes:</p>
                        <br/>
                        <p>Time to stretch your legs</p>
                        <br/>
                        <p>Feel free to wander around a bit, but please don't stir up a ruckus for the others still grinding away at their tests.</p>
                        <br/>
                        <p>Keep the app open and your device awake.</p>
                        <br/>
                        <p>We won't jump-start without you, promise!</p>
                        <br/>
                        <p>Here are a couple of break-time don'ts:</p>
                        <ol>
                            <li style={{ marginTop: "0 !important"}}>Keep your phone, fancy wrist gadgets, textbooks, scribbles, or cyber world at bay.</li>
                            <li style={{ marginTop: "0 !important"}}>Save the munchies and sips for later, not in the test room, okay?</li>
                            <li style={{ marginTop: "0 !important"}}>Shhh... let's keep the room as quiet as a library. Once you step outside, no chit-chat about the exam, deal?</li>
                        </ol>
                        <br/>
                        <p>Take a breather and we'll dive back in when you're ready!</p>
                    </TimeTitle>
                    </DescriptionInnerWrpaper>
                </DescriptionWrpaper>
            </InnerWrapper>
        </Wrapper>
    )
}

const Wrapper = styled(BaseWrapper)`
  background-color: black;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  padding: 15px;
  height: 100%
`;

const TimeWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  justify-content: center;
  align-items: center;
`;

const TimeTitle = styled(Header)`
  color: white;
`;

const Timer = styled(BaseTimer)`
  color: white;
  font-size: 48px;
  margin-top: 30px;
`;

const NextButton = styled(Button)`
  color: white;
  font-weight: 700;
  padding: 16px 24px;
  border: none;
  background-color: forestgreen;
  border-radius: 31px;
  max-width: 233px;
  width: 100%;
  margin-top: 56px;
  cursor: pointer;
`;

const Splitter = styled.div`
  width: 3px;
  background-color: white;
`;

const DescriptionWrpaper = styled.div`
  flex: 1;
  padding: 30px;
  overflow-y: auto;
  height: 100%;
`;

const DescriptionInnerWrpaper = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
`;
