import styled from "styled-components";
import {Header, Title} from "styles/texts";

export function TestModuleReview({sectionSeq, moduleSeq, sectionName, questionSets, navigateTo}) {
    function checkAnswered(questionSet) {
        return questionSet.get("solving_type") === 0 ? questionSet.get("choices").filter((v) => v.get("selected") === true).size > 0 : questionSet.get("user_input") !== "";
    }

    function checkReview(questionSet) {
        return questionSet.get("review");
    }

    return (
        <Wrapper>
            <TitleWrapper>
                <Header>Check Your Work</Header>
                <Title>On test day, you won't be able to...</Title>
            </TitleWrapper>
            <ReviewPanel>
                <PanelHeader>
                    <PanelTitle>Section {sectionSeq}, Module {moduleSeq}: {sectionName}</PanelTitle>
                </PanelHeader>
                <PanelBody>
                    {questionSets.map((questionSet, idx) => (
                        <Question key={`${questionSet.get("seq")}`} onClick={() => navigateTo(idx)} className={[checkAnswered(questionSet) && "answered", checkReview(questionSet) && "review"]}>{questionSet.get("seq")}</Question>
                    ))}
                </PanelBody>
            </ReviewPanel>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;
  width: 100%;
  height: 100%;
`;

const TitleWrapper = styled.div`
  text-align: center;
  padding-bottom: 18px;
`;

const ReviewPanel = styled.div`
  background-color: white;
  box-shadow: 5px 7px 17px rgba(0, 0, 0, 0.24);
  border-radius: 14px;
  max-width: 884px;
  width: 100%;
  padding: 15px;
`;

const PanelHeader = styled.div`
  border-bottom: 1px solid black;
`;

const PanelTitle = styled(Title)`
  padding: 15px
`;

const PanelBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  padding: 15px;
`;

const Question = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 18px;
  border: 1px solid black;
  cursor: pointer;
  
  &.answered {
    color: white;
    background-color: black;
  }
  
  &.review {
    color: white;
    background-color: red;
  }
`;
