import {useEffect, useState} from "react";
import {Navigate, Outlet, useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import * as colors from "styles/colors";
import "assets/styles/table-question.css";
import {Footer, Header} from "sections/test/main";
import {Wrapper} from "styles/layouts";
import ReactModal from "react-modal-resizable-draggable";
import SATAsset1 from "assets/sat-assets/1.svg";
import SATAsset2 from "assets/sat-assets/2.svg";
import SATAsset3 from "assets/sat-assets/3.svg";
import SATAsset4 from "assets/sat-assets/4.svg";
import SATAsset5 from "assets/sat-assets/5.svg";
import SATAsset6 from "assets/sat-assets/6.svg";
import SATAsset7 from "assets/sat-assets/7.svg";
import SATAsset8 from "assets/sat-assets/8.svg";
import SATAsset9 from "assets/sat-assets/9.svg";
import SATAsset10 from "assets/sat-assets/10.svg";
import SATAsset11 from "assets/sat-assets/11.svg";
import BrandLineSvg from "assets/brand_line.svg";
import {useTest} from "hooks";

export function TestLayout() {
    const {uid} = useParams();
    const { testSheet, module, questionSets, currentQuestionSetIdx, setCurrentQuestionSetIdx, submitTestModule } = useTest();
    const [modal, setModal] = useState(0);
    const navigate = useNavigate();

    function navigateQuestion(idx) {
        // if (questionSets.size > 0) {
        //     if (idx > -1 && idx < questionSets.size) {
        //         setShowReview(false);
        //         setCurrentQuestionIdx(idx);
        //     } else if (idx === questionSets.size) {
        //         setCurrentQuestionIdx(idx);
        //         setShowReview(true);
        //     } else if (idx > questionSets.size) {
        //         if (showReview) {
        //             const item = testSheet.setIn(["sections", sectionIdx, "test_modules", moduleIdx, "submitted"], true);
        //             setTestSheet(item);
        //         }
        //     }
        // }
        if (idx > -1 && idx < questionSets.size) {
            setCurrentQuestionSetIdx(idx);
            navigate(`/test_center/tests/${testSheet.get("uid")}/take`, { replace: true });
        }
        if (idx === questionSets.size) {
            setCurrentQuestionSetIdx(idx);
            navigate(`/test_center/tests/${testSheet.get("uid")}/review`, { replace: true });
        }
        if (idx > questionSets.size) {
            submitTestModule();
        }
    }

    return testSheet && module && questionSets ? (
        <Wrapper>
            <HeaderWrapper>
                <Header
                    sectionSeq={module.getIn(["section", "seq"])}
                    moduleSeq={module.get("seq")}
                    sectionName={module.getIn(["section", "name"])}
                    openModal={(contentIdx) => setModal(contentIdx)}
                />
            </HeaderWrapper>
            <LineWrapper />
            <BodyWrapper>
                <Outlet/>
            </BodyWrapper>
            <LineWrapper />
            <FooterWrapper>
                <Footer
                    showReview={currentQuestionSetIdx === questionSets.size}
                    currentIdx={currentQuestionSetIdx + 1}
                    questionNumbers={questionSets.size}
                    navigateNext={() => navigateQuestion(currentQuestionSetIdx + 1)}
                    navigateBack={() => navigateQuestion(currentQuestionSetIdx - 1)}
                    sectionName={module.getIn(["section", "name"])}
                    sectionSeq={module.getIn(["section", "seq"])}
                    moduleSeq={module.get("seq")}
                    questionSets={questionSets}
                    navigateTo={navigateQuestion}
                />
            </FooterWrapper>
            <ReactModal
                initWidth={800}
                initHeight={400}
                disableResize={true}
                className={"my-modal-custom-class"}
                onRequestClose={() => setModal(0)}
                isOpen={modal !== 0}>
                {modal === 1 && (
                    <div style={{ marginTop: 12, height: "348px", overflowY: "scroll" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end", paddingRight: 18, width: "100%"}}>
                            <button
                                style={{ cursor: "pointer", zIndex: 999, border: "none", background: "transparent", color: "white", backgroundColor: colors.infoColor, padding: 8, borderRadius: 8 }}
                                onClick={() => setModal(0)}
                            >Close</button>
                        </div>
                        <div style={{ overflowY: "hidden" }}>
                            <link href="/assets/sat-assets/sat.css" rel="stylesheet" />

                            <script src="/prevent-right-click.js"></script>
                            <script src="/keyboard-close.js"></script>
                            <div className="reference-images">
                                <div>
                                    <div>
                                        <img
                                            alt="Reference figure 1 is a circle with radius r. Two equations are presented below reference figure 1. A equals pi times the square of r. ; C equals 2 pi r. "
                                            src={SATAsset1}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            alt="Reference figure 2 is a rectangle with length l and width w. An equation is presented below reference figure 2. A equals l w. "
                                            src={SATAsset2}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img
                                            alt="Reference figure 3 is a triangle with base b and height h. An equation is presented below reference figure 3. A equals one half b h. "
                                            src={SATAsset3}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            alt="Reference figure 4 is a right triangle. The two sides that form the right angle are labeled a and b, and the side opposite the right angle is labeled c. An equation is presented below reference figure 4. c squared equals a squared plus b squared "
                                            src={SATAsset4}
                                        />
                                    </div>
                                </div>
                                <div className="special-right-triangles-container">
                                    <div role="region" aria-labelledby="specialRightTrianglesText">
                                        <div>
                                            <img
                                                alt="Reference figure 5 is a right triangle with a 30 degree angle and a 60 degree angle. The side opposite the 30 degree angle is labeled x. The side opposite the 60 degree angle is labeled x times the square root of 3. The side opposite the right angle is labeled 2 x. Reference figure 6 is a right triangle with two 45 degree angles. Two sides are each labeled s. The side opposite the right angle is labeled s times the square root of 2. "
                                                src={SATAsset5}
                                            />
                                        </div>
                                        <div>
                                            <img
                                                alt="Reference figure 6 is a right triangle with two 45 degree angles. Two sides are each labeled s. The side opposite the right angle is labeled s times the square root of 2. "
                                                src={SATAsset6}
                                            />
                                        </div>
                                    </div>
                                    <div id="specialRightTrianglesText">Special Right Triangles</div>
                                </div>
                                <div>
                                    <div>
                                        <img
                                            alt="Reference figure 7 is a rectangular solid whose base has length l and width w and whose height is h. An equation is presented below reference figure 7. V equals l w h. "
                                            src={SATAsset7}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            alt="Reference figure 8 is a right circular cylinder whose base has radius r and whose height is h. An equation is presented below reference figure 8. V equals pi times the square of r times h."
                                            src={SATAsset8}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img
                                            alt="Reference figure 9 is a sphere with radius r. An equation is presented below reference figure 9. V equals four thirds pi times the cube of r. "
                                            src={SATAsset9}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            alt="Reference figure 10 is a cone whose base has radius r and whose height is h. An equation is presented below reference figure 10. V equals one third times pi times the square of r times h. "
                                            src={SATAsset10}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <img
                                            alt="Reference figure 11 is an asymmetrical pyramid whose base has length l and width w and whose height is h. An equation is presented below reference figure 11. V equals one third l w h"
                                            src={SATAsset11}
                                        />
                                    </div>
                                    <div></div>
                                </div>
                            </div>
                            <div className="reference-text">
                                <p>The number of degrees of arc in a circle is&nbsp;360.</p>
                                <p>The number of radians of arc in a circle is&nbsp;2<i>π</i>.</p>
                                <p>The sum of the measures in degrees of the angles of a triangle is&nbsp;180.</p>
                            </div>
                        </div>
                    </div>
                )}
            </ReactModal>
        </Wrapper>
    ) : (
        <Navigate to={`/test_center/tests/${uid}/loading`} />
    )
}

const HeaderWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #BABABA;
  box-shadow: rgba(0, 0, 0, 0.24) 0 1px 5px;
`;

const BodyWrapper = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: hidden;
`;

const FooterWrapper = styled.div`
  width: 100%;
  border-top: 1px solid #BABABA;
  box-shadow: rgba(0, 0, 0, 0.24) 0 1px 5px;
`;

const LineWrapper = styled.div`
  width: 100%;
  height: 3px;
  background-image: url("${BrandLineSvg}");
  background-size: auto 100%;
`;
