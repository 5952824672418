import axios from "axios";

export async function requestTestModuleQuestionSets(moduleUid) {
    const response = await axios.get(`/test_center/modules/${moduleUid}/questions/`);
    return response.data;
}

export async function requestTestModuleSubmit(testSheetUid, moduleUid, responses) {
    const response = await axios.post(`/test_center/tests/${testSheetUid}/submit/`, {
        module: moduleUid,
        responses: Object.entries(responses.toObject()).map(([key, value]) => ({
            question_set: key,
            response: value,
        }))
    });
    return response.status === 201;
}

export async function requestSubmissionList(testSheetUid) {
    const response = await axios.get(`/test_center/tests/${testSheetUid}/submit/`);
    return response.data;
}

export async function requestTestResultList() {
    const response = await axios.get(`/test_center/results/`);
    return response.data;
}

export async function requestTestResultDetail(testSheetUid) {
    const response = await axios.get(`/test_center/results/${testSheetUid}/`);
    return response.data;
}
