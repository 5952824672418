import axios from "axios";


export async function requestTestSheetCreate(testSetUid) {
    const response = await axios.post(`/test_center/prepare/`, {
        test_set: testSetUid,
    });
    return response.data;
}

export const requestTestResults = async () => {
    const response = await axios.get("/playground/results/");
    return response.data;
}
