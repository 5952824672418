import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {Timer} from "components";
import {Menu} from "components/Menu";
import {Body1} from "styles/texts";
import AnnoateIconImage from "assets/annotate_icon.png";
import CalculatorIconImage from "assets/calculator_icon.png";
import FunctionReferenceIconImage from "assets/function_reference_icon.png";

export function Header({sectionSeq, moduleSeq, sectionName, openModal}) {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <TitleSection>
                Section {sectionSeq}, Module {moduleSeq} : {sectionName}
            </TitleSection>
            <Timer />
            <MenuSection>
                {sectionSeq === 1 && (
                    <IconWrapper>
                        <AnnotateIcon src={AnnoateIconImage}/>
                        <IconDescription>Annotate</IconDescription>
                    </IconWrapper>
                )}
                {sectionSeq === 2 && (
                    <IconWrapper onClick={() => openModal(1)}>
                        <CalculatorIcon src={FunctionReferenceIconImage}/>
                        <IconDescription>Reference</IconDescription>
                    </IconWrapper>
                )}
                {sectionSeq === 2 && (
                    <IconWrapper>
                        <a
                            href={"https://www.desmos.com/calculator"}
                            target={"_blank"}
                            refer={"noreferrer"}
                            style={{
                                textAlign: "center",
                                color: "black",
                                textDecoration: "none",
                            }}
                        >
                            <CalculatorIcon src={CalculatorIconImage}/>
                            <IconDescription style={{ marginTop: 2 }}>Calculator</IconDescription>
                        </a>
                    </IconWrapper>
                )}
                <Menu
                    menuList={[
                        {
                            title: "Save & Exit",
                            onClick: () => navigate("/"),
                        }
                    ]}
                />
            </MenuSection>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 32px;
`;

const TitleSection = styled(Body1)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const MenuSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  gap: 8px;
  cursor: pointer;
`;

const IconDescription = styled.div`
  font-size: 10px;
`;

const CalculatorIcon = styled.img`
  width: auto;
  height: 24px;
`;

const AnnotateIcon = styled.img`
  width: auto;
  height: 24px;
`;
