import axios from "axios";

export async function requestTestSets() {
    const response = await axios.get("/playground/available_tests/");
    return response.data;
}

export async function requestLatestTestSetVersion(testSetuid) {
    const response = await axios.get(`/test_center/available_sets/${testSetuid}/`);
    return response.data;
}

export async function requestTestInformation(testSheetUid) {
    const response = await axios.get(`/test_center/tests/${testSheetUid}/`);
    return response.data;
}

export async function requestTestProgress(testSheetUid) {
    const response = await axios.get(`/test_center/tests/${testSheetUid}/progress/`);
    return response;
}

export async function requestTestSheetDelete(testSheetUid) {
    const response = await axios.get(`/test_center/tests/${testSheetUid}/delete/`);
    return response;
}

export async function createTestSheetSubmit(testSet) {
    const test_sheet_uid = testSet.get("test_sheet_uid");
    const data = [];
    for (const section of testSet.get("sections")) {
        for (const module of section.get("test_modules")) {
            for (const questionSet of module.get("question_sets")) {
                data.push({
                    test_sheet: test_sheet_uid,
                    question_set: questionSet.get("uid"),
                    response: questionSet.get("solving_type") === 0 ? questionSet.get("choices").filter((v) => v.get("selected") === true).getIn([0, "seq"]) : questionSet.get("user_input"),
                });
            }
        }
    }
    console.log(data);
    const response = await axios.post(`/test_sheets/sheets/${test_sheet_uid}/submit/`, data);
    if (response.status === 200)
        window.localStorage.removeItem(test_sheet_uid);
    return response.status === 200;
}

export async function requestTestSheets() {
    const response = await axios.get('/test_center/running_tests/');
    return response.data;
}

export async function requestTestResults() {
    const response = await axios.get('/test_sheets/sheets/?submitted=true');
    return response.data;
}

export async function requestTestResult(uid) {
    const response = await axios.get(`/test_sheets/sheets/${uid}`);
    return response.data;
}
