import styled from "styled-components";


export const Button = styled.button`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
`;

export const Link = styled.a`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;
