import {useState} from "react";
import styled from "styled-components";
import { Body1 } from "styles/texts";
import {ReviewDialog} from "./ReviewDialog";
import {useAuth} from "hooks";

export function Footer({navigateNext, navigateBack, showReview, currentIdx, questionNumbers, sectionName, sectionSeq, moduleSeq, questionSets,navigateTo}) {
    const [showReviewDialog, setShowReviewDialog] = useState(false);
    const { user } = useAuth();

    return (
        <Wrapper>
            <UserWrapper>
                {user.first_name} {user.last_name}
            </UserWrapper>
            <ReviewWrapper>
                {showReviewDialog && (
                    <Dialog
                        sectionName={sectionName}
                        sectionSeq={sectionSeq}
                        moduleSeq={moduleSeq}
                        questionSets={questionSets}
                        navigateTo={navigateTo}
                        setShowReviewDialog={setShowReviewDialog}
                    />
                )}
                {!showReview && <ReviewButton onClick={() => setShowReviewDialog(prev => !prev)}>Question {currentIdx} of {questionNumbers}</ReviewButton>}
            </ReviewWrapper>
            <NavigationWrapper>
                <NavigateButton onClick={() => navigateBack()}>Back</NavigateButton>
                <NavigateButton onClick={() => navigateNext()}>Next</NavigateButton>
            </NavigationWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 32px;
  position: relative;
`;

const UserWrapper = styled(Body1)`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 700;
`;

const ReviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReviewButton = styled.button`
  color: white;
  background-color: black;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 700;
  cursor: pointer;
  
  :after {
    content: '>';
    font-weight: 700;
    margin-left: 6px;
  }
`;

const NavigationWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

const NavigateButton = styled.button`
  color: white;
  border: none;
  background-color: black;
  border-radius: 30px;
  font-weight: 700;
  padding: 16px 24px;
  cursor: pointer;
`;

const Dialog = styled(ReviewDialog)`
  position: absolute;
  bottom: 0;
  margin-bottom: 168px;
`;
