import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useLocalStorage} from "./useLocalStorage";

const TimerContext = createContext(null);

export const TimerProvider = ({ children }) => {
    const [endTime, setEndTime] = useState(null);
    const [milliSeconds, setMilliSeconds] = useState(null);
    const [timerOnState, setTimerOnState] = useState(false);

    async function setStartTime(milliSeconds) {
        const currentTime = new Date();
        setMilliSeconds(milliSeconds);
        setEndTime((new Date(currentTime.getTime() + milliSeconds)));
    }

    useEffect(() => {
        if (timerOnState && milliSeconds > 0) {
            const timeInterval = setInterval(() => {
                setMilliSeconds(endTime - new Date());
                // return clearInterval(timeInterval);
                return clearTimeout(timeInterval);
            }, 10);
        } else if (milliSeconds <= 0) {
            setTimerOnState(false);
        }
    }, [timerOnState, milliSeconds, endTime]);

    const value = useMemo(
        () => ({
            milliSeconds,
            timerOnState,
            setTimerOnState,
            setStartTime,
        }),
        [milliSeconds, timerOnState]
    );

    return <TimerContext.Provider value={value}>{children}</TimerContext.Provider>
}

export const useTimer = () => {
    return useContext(TimerContext);
}
