import styled from "styled-components";
import {Wrapper} from "styles/layouts";
import {Header, Title} from "styles/texts";
import {Button} from "styles/buttons";
import {useNavigate} from "react-router-dom";

export function TestOverGuide() {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <InnerWrapper>
                <Header>You're All Finished!</Header>
                <DescriptionWrapper>
                    <Title>Your test is complete, and your answers have been submitted.</Title>
                    <Title>Your proctor will excuse you when it's time to go.</Title>
                    <Title>Please be quiet; other students may still be testing.</Title>
                    <Title>Report will be delivered with email when scoring is done.</Title>
                </DescriptionWrapper>
                <HomeButton onClick={() => navigate("/main")}>Return To Home</HomeButton>
            </InnerWrapper>
        </Wrapper>
    )
}

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const DescriptionWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`;

const HomeButton = styled(Button)`
  color: white;
  padding: 16px 24px;
  font-weight: 700;
  background-color: black;
  border-radius: 31px;
  border: none;
  margin-top: 58px;
  text-decoration: none;
`;
