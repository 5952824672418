import {useEffect} from "react";
import {MathJaxContext} from "better-react-mathjax";
import {QuestionSolve} from "sections/test/main";
import {useTest, useTimer} from "hooks";
import "assets/sat-assets/sat.css";
import {useLocalStorage} from "../hooks/useLocalStorage";

export function TestPage() {
    const { milliSeconds, setTimerOnState } = useTimer();
    const { testSheet, submitTestModule } = useTest();
    const [timerSetting] = useLocalStorage(`${testSheet.get("uid")}_timer`, -1)

    useEffect(function () {
        if (timerSetting !== -1)
            setTimerOnState(true);
    }, []);

    useEffect(() => {
        if (milliSeconds <= 0) {
            submitTestModule();
        }
    }, [milliSeconds]);

    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ]
        }
    };

    return (
        <>
            {/*{!showReview && questionSets.size > 0 && (*/}
                <MathJaxContext src={"https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"} version={3} config={config}>
                    <QuestionSolve />
                </MathJaxContext>
            {/*)}*/}
            {/*{showReview && (*/}
            {/*    <TestModuleReview*/}
            {/*        sectionName={testSheet.getIn(["sections", sectionIdx, "name"])}*/}
            {/*        sectionSeq={testSheet.getIn(["sections", sectionIdx, "seq"])}*/}
            {/*        moduleSeq={testSheet.getIn(["sections", sectionIdx, "test_modules", moduleIdx, "seq"])}*/}
            {/*        questionSets={questionSets}*/}
            {/*        navigateTo={navigateQuestion}*/}
            {/*    />*/}
            {/*)}*/}
        </>
    )
}
