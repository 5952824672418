import {useState, useEffect} from "react";
import styled from "styled-components";
import {MathJax} from "better-react-mathjax";

export function AnswerChoice({seq, content, selected, strikethrough, setSelected, setStrikethrough, viewOnly = false}) {

    return (
        <Wrapper>
            <ChoiceWrapper onClick={() => !viewOnly && setSelected()}>
                <InnerWrapper className={[strikethrough && "strikethrough", selected && "selected"]} >
                    <SeqWrapper>{seq.toUpperCase()}</SeqWrapper>
                    <ContentWrapper dangerouslySetInnerHTML={{ __html: content }}></ContentWrapper>
                </InnerWrapper>
                {strikethrough && (
                    <StrikethroughWrapper>
                        <Strikethrough />
                    </StrikethroughWrapper>
                )}
            </ChoiceWrapper>
            {!viewOnly && (
                <StrikethroughButtonWrapper onClick={() => setStrikethrough()}>
                    {!strikethrough ? (
                        <StrikethroughButton >{seq.toUpperCase()}</StrikethroughButton>
                    ) : (
                        <div onClick={() => setStrikethrough()}><u>Undo</u></div>
                    )}
                    {!strikethrough && (
                        <StrikethroughWrapper>
                            <Strikethrough />
                        </StrikethroughWrapper>
                    )}
                </StrikethroughButtonWrapper>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChoiceWrapper = styled.div`
  flex: 1;
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  cursor:pointer;
`;

const SeqWrapper = styled.div`
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 100%;
  background-color: white;
  width: 0;
  height: 0;
  padding: 12px;
  position: relative;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px 18px;
  
  &.strikethrough {
    color: #BFBFBF;
    border-color: #BFBFBF;

    ${SeqWrapper} {
      color: #BFBFBF;
      border-color: #BFBFBF;
    }
  }
  
  &.selected {
    color: white;
    background-color: black;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  min-height: 24px;
  flex: 1;
`;

const StrikethroughButtonWrapper = styled.div`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  cursor:pointer;
`;

const StrikethroughButton = styled(SeqWrapper).attrs({as: 'button'})`
  cursor:pointer;
`;


const StrikethroughWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Strikethrough = styled.div`
  width: calc(100% - 5px);
  height: 2px;
  background-color: black;
`;
