import styled from "styled-components";

const defaultHeader = styled.header`
  color: #000000;
  margin: 0;
  padding: 0;
  word-break: keep-all;
`;

const defaultText = styled.p`
  color: #000000;
  margin: 0;
  padding: 0;
  word-break: keep-all;
`;

export const Headline = styled(defaultHeader)`
  font-size: 48px;
  font-weight: 700;
  line-height: 40px;
`;

export const ThinHeadline = styled(defaultHeader)`
  font-size: 48px;
  font-weight: 300;
  line-height: 48px;
`;

export const Header = styled(defaultHeader)`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const Header2 = styled(defaultHeader)`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
`;

export const Title = styled(defaultText)`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
`;

export const Title2 = styled(defaultText)`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

export const Body1 = styled(defaultText)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

export const Body2 = styled(defaultText)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`;

export const Body3 = styled(defaultText)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const Body4 = styled(defaultText)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
