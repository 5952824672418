import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {requestTestModuleQuestionSets, requestTestProgress} from "apis";
import {fromJS} from "immutable";
import {useTimer} from "./TimerProvider";
import {useLocalStorage} from "./useLocalStorage";
import {useLocalForage} from "./useLocalForage";

const TestContext = createContext(null);

export const TestProvider = ({children}) => {
    const {uid: testSheetUid} = useParams();
    const [testSheet, setTestSheet] = useState(null);
    const [module, setModule] = useState(null);
    const [questionSets, setQuestionSets] = useState(null);
    const [responses, setResponses] = useLocalStorage("responses", null);
    const [strikethroughs, setStrikethroughs] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [currentQuestionSetIdx, setCurrentQuestionSetIdx] = useState(null);
    const { setStartTime, setTimerOnState, milliSeconds } = useTimer();
    const [storage, setStorage] = useLocalStorage(`${testSheetUid}_responses`, {});
    const [timeStorage, setTimeStorage] = useLocalStorage(`${testSheetUid}_millisec`, null);
    const [timerSetting, setTimerSetting] = useLocalStorage(`${testSheetUid}_timer`, 1);
    const [holdTimer, setHoldTimer] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (responses !== null) {
            setStorage(responses);
        }
    }, [responses]);

    useEffect(() => {
        if (milliSeconds !== null && holdTimer === false) {
            if (milliSeconds <= 0) {
                setTimeStorage(null);
            } else {
                setTimeStorage(milliSeconds);
            }
        }
    }, [milliSeconds]);

    async function loadCurrentModule(test) {
        const uid = test?.get("uid");
        if (uid !== undefined) {
            const progress = await requestTestProgress(uid);
            if (progress.status === 204) {
                navigate("/test_center/tests/over", { replace: true });
            } else {
                const nextModule = progress.data;
                const questionSets = await requestTestModuleQuestionSets(nextModule.uid);
                const res = {};
                const st = {};
                const rv = {};
                for (const questionSet of questionSets) {
                    res[questionSet.uid] = storage?.[questionSet.uid] ?? "";
                    st[questionSet.uid] = {};
                    for (const choice of questionSet.answer_choices) {
                        st[choice.uid] = false;
                    }
                    rv[questionSet.uid] = false;
                }
                const needRest = module && module.getIn(["section", "seq"]) !== nextModule.section.seq;
                setModule(fromJS(nextModule));
                setQuestionSets(fromJS(questionSets));
                setStrikethroughs(fromJS(st));
                setResponses(fromJS(res));
                setReviews(fromJS(rv));
                if (timerSetting !== -1) {
                    setStartTime(timeStorage === null ? nextModule.time * parseFloat(timerSetting) : timeStorage);
                } else {
                    setStartTime(1);
                }
                setCurrentQuestionSetIdx(0);
                setSubmit(false);
                moveToNextModule(uid, needRest);
            }
        }
    }

    function moveToNextModule(testSheetUid, needRest) {
        if (needRest) {
            setHoldTimer(true);
            setTimeStorage(null);
            navigate(`/test_center/tests/${testSheetUid}/rest`, { replace: true });
        } else {
            setHoldTimer(false);
            navigate(`/test_center/tests/${testSheetUid}/take`, { replace: true });
        }
    }

    function markQuestionSetForReview(questionSetUid, isReviewMarked) {
        setReviews(prev => prev.set(questionSetUid, isReviewMarked));
    }

    function answerQuestion(questionSetUid, response) {
        setResponses(prev => prev.set(questionSetUid, response));
    }

    function strikethroughAnswerChoice(choiceUid, isStrikethroughMarked) {
        setStrikethroughs(prev => prev.set(choiceUid, isStrikethroughMarked));
    }

    function submitTestModule() {
        setTimerOnState(false);
        setTimeStorage(null);
        setSubmit(true);
        navigate(`/test_center/tests/${testSheet.get("uid")}/loading`, { replace: true });
    }

    const value = useMemo(() => ({
        testSheet,
        module,
        questionSets,
        currentQuestionSetIdx,
        responses,
        reviews,
        strikethroughs,
        submit,
        setTestSheet,
        setCurrentQuestionSetIdx,
        setSubmit,
        markQuestionSetForReview,
        strikethroughAnswerChoice,
        answerQuestion,
        moveToNextModule,
        loadCurrentModule,
        submitTestModule,
    }), [testSheet, module, questionSets, currentQuestionSetIdx, reviews, responses, strikethroughs, submit]);

    return (
        <TestContext.Provider value={value}>{children}</TestContext.Provider>
    )
}

export const useTest = () => {
    return useContext(TestContext);
}
