import {useEffect, useRef} from "react";
import {Route, Navigate, Routes, HashRouter, Outlet, BrowserRouter} from "react-router-dom";
import {MainPage, SignInPage, TestStoragePage, TestResultListPage, TestResultDetailPage} from "pages";
import {TestPage} from "pages/TestPage";
import {TestLoading} from "pages/TestLoading";
import {TestRest} from "pages/TestRest";
import {TestOverGuide} from "pages/TestOverGuide";
import {AuthProvider, TimerProvider} from "hooks";
import {TestContainer, TestLayout, ProtectedRoute} from "./components";
import {TestReview} from "./pages/TestReview";
import {Layout} from "./components/Layout";
import {requestStartUpInfo} from "./apis/setup";

function App() {
    const documentDefined = typeof document !== 'undefined';
    const originalTitle = useRef(documentDefined ? document.title : null);

    useEffect(() => {
        if (!documentDefined) return;

        const host = window.location.host;
        const tag = host.split(".")[0];

        (async () => {
            const info = await requestStartUpInfo(tag);

            document.title = info.name;
            const link = document.querySelector("link[rel='icon']");
            link.href =
                info.icon;
            const apple_icon = document.querySelector("link[rel='apple-touch-icon']");
            apple_icon.href =
                info.icon;
        })();

        const manifest = document.querySelector("link[rel='manifest']");
        manifest.href =
            `${process.env.REACT_APP_BASE_URL}/playground/manifest.json?tag=${tag}`;

        return () => {
            //@ts-ignore
            document.title = originalTitle.current;
        };
    }, []);

    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path={""} element={<ProtectedRoute><Layout/></ProtectedRoute>}>
                        <Route index element={<MainPage/>}/>
                        <Route path={"tests"}>
                            <Route index element={<TestStoragePage/>}/>
                        </Route>
                        <Route path={"results"}>
                            <Route index element={<TestResultListPage/>}/>
                            <Route path={":uid"} element={<TestResultDetailPage/>}/>
                        </Route>
                    </Route>
                    <Route path={"test_center"} element={<ProtectedRoute><Outlet/></ProtectedRoute>}>
                        <Route path={"tests"}>
                            <Route path={"over"} element={<TestOverGuide />}/>
                            <Route path={":uid"} element={<TestContainer/>}>
                                <Route path={"loading"} element={<TestLoading/>}/>
                                <Route path={"rest"} element={<TestRest/>}/>
                                <Route element={<TestLayout/>}>
                                    <Route path={"take"} element={<TestPage/>}/>
                                    <Route path={"review"} element={<TestReview/>}/>
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    {/*    <Route path={"tests"}>*/}
                    {/*        <Route index element={*/}
                    {/*            <ProtectedRoute>*/}
                    {/*                <TestStorage />*/}
                    {/*            </ProtectedRoute>*/}
                    {/*        } />*/}
                    {/*        <Route path={"over"} element={*/}
                    {/*            <ProtectedRoute>*/}
                    {/*                <TestOverGuide />*/}
                    {/*            </ProtectedRoute>*/}
                    {/*        } />*/}
                    {/*        <Route path={"results"} element={*/}
                    {/*            <ProtectedRoute>*/}
                    {/*                <TestResults />*/}
                    {/*            </ProtectedRoute>*/}
                    {/*        } />*/}
                    {/*        <Route path={"results/:uid"} element={*/}
                    {/*            <ProtectedRoute>*/}
                    {/*                <TestResultDetail />*/}
                    {/*            </ProtectedRoute>*/}
                    {/*        } />*/}
                    {/*        <Route path={":uid"} element={*/}
                    {/*            <ProtectedRoute>*/}
                    {/*                <TestLoading />*/}
                    {/*            </ProtectedRoute>*/}
                    {/*        } />*/}
                    {/*    </Route>*/}
                    {/*    <Route path={"test_sheets/:uid"} element={*/}
                    {/*        <ProtectedRoute>*/}
                    {/*            <TimerProvider>*/}
                    {/*                <TestPage />*/}
                    {/*            </TimerProvider>*/}
                    {/*        </ProtectedRoute>*/}
                    {/*    } />*/}
                    {/*    <Route path={"test_sheets/:uid/rest"} element={*/}
                    {/*        <ProtectedRoute>*/}
                    {/*            <TimerProvider>*/}
                    {/*                <TestRest />*/}
                    {/*            </TimerProvider>*/}
                    {/*        </ProtectedRoute>*/}
                    {/*    } />*/}
                    {/*    <Route path={"test_sheets/:uid/submit"} element={*/}
                    {/*        <ProtectedRoute>*/}
                    {/*            <TestOver />*/}
                    {/*        </ProtectedRoute>*/}
                    {/*    } />*/}
                    {/*</Route>*/}
                    <Route path={"sign_in"} element={<SignInPage />} />
                    <Route path={"*"} element={<Navigate to={"/"} />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}

export default App;
