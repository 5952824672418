import styled from "styled-components";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAuth} from "hooks";
import LogoImage from "assets/full_logo.png";
import BackgroundImage from "assets/background.svg";
import {requestCSRFToken, requestLogin} from "apis";
import {useEffect, useState} from "react";
import {requestStartUpInfo} from "../apis/setup";

export function SignInPage() {
    const [urlSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const next = urlSearchParams.get('next');
    const [basicInfo, setBasicInfo] = useState(null);
    const { login } = useAuth();
    requestCSRFToken();

    useEffect(() => {
        (async () => {
            const info = await requestStartUpInfo(window.location.host.split(".")[0]);
            setBasicInfo(info);
        })();
    }, []);

    async function signIn(e) {
        e.preventDefault();
        try {
            await requestLogin(e.target);
        } catch (error) {
            alert(`(${error.response.status}) ${Object.keys(error.response.data).map((k) => error.response.data[k])}`);
        }
        await login();
        if (next) {
            navigate(next, { replace: true });
        } else {
            navigate("/", { replace: true });
        }
        return false;
    }

    const LL = () => {
        if (basicInfo !== null)
            return <Logo src={basicInfo.logo} />
        else
            return <Logo src={LogoImage}/>
    }

    const SB = () => {
        if (basicInfo !== null)
            return;
        else
            return <SignUpButton href={"https://test-qube.com/sign-up"} target={"_blank"} refer={"noreferrer"}>Sign Up</SignUpButton>;
    }

    const OrganizationInput = () => {
        if (basicInfo !== null)
            return <input type={"hidden"} name={"organization"} value={window.location.host.split(".")[0]}/>
        else
            return (
                <FormElement>
                    <FormLabel>Organization Tag</FormLabel>
                    <FormInput type={"text"} name={"organization"} />
                </FormElement>
            );
    }

    return (
        <Wrapper>
            <InnerWrapper>
                <InnerWrapper2>
                    <LL/>
                    <Header>Sign-In</Header>
                    <FormWrapper>
                        <FormInnerWrapper onSubmit={signIn}>
                            <OrganizationInput/>
                            <FormElement>
                                <FormLabel>Email</FormLabel>
                                <FormInput type={"email"} name={"username"} />
                            </FormElement>
                            <FormElement>
                                <FormLabel>Password</FormLabel>
                                <FormInput type={"password"} name={"password"} />
                                <FormLabelWrapper>
                                    <a style={{ whiteSpace: "nowrap" }} href={"https://test-qube.com/accounts/password_reset/"} target={"_blank"} rel={"noreferrer"}>Forget your password?</a>
                                </FormLabelWrapper>
                            </FormElement>
                            <SubmitButtonWrapper>
                                <SubmitButton>Submit</SubmitButton>
                                <SB/>
                            </SubmitButtonWrapper>
                        </FormInnerWrapper>
                    </FormWrapper>
                </InnerWrapper2>
            </InnerWrapper>
        </Wrapper>
    )
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url("${BackgroundImage}");
  background-size: cover;
  background-repeat: no-repeat;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1156px;
  //max-height: 950px;
  flex-shrink: 0;
  overflow-y: scroll;
`;

const InnerWrapper2 = styled.div`
  //height: 100%;
  background-color: white;
  border-radius: 30px;
  padding: 42px 58px;
  margin: 18px 38px;
`;

const Header = styled.div`
  color: #292929;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 104px;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
`;

const Logo = styled.img`
  width: 200px;
`;

const FormInnerWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 48px;
  text-align: center;
`;

const FormElement = styled.div`
  text-align: start;
`;

const FormLabelWrapper = styled.div`
  text-align: end;
  margin-top: 8px;
`;

const FormLabel = styled.label`
  font-size: 18px;
  text-align: start;
  width: 100%;
`;

const FormInput = styled.input.attrs({'required': true})`
  flex: 1;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 8px 14px;
  font-size: 18px;
  margin-top: 4px;
  box-sizing: border-box;
`;

const SubmitButtonWrapper = styled.div``;

const SubmitButton = styled.button.attrs({type: "submit"})`
  color: white;
  padding: 12px 32px;
  border: none;
  border-radius: 32px;
  background-color: #707BFC;
  margin-top: 82px;
  cursor: pointer;
  width: 100%;
`;

const SignUpButton = styled.a`
  display: block;
  color: #707BFC;
  padding: 12px 32px;
  border: 1px solid #707BFC;
  border-radius: 40px;
  //margin-top: 82px;
  cursor: pointer;
  margin-top: 24px;
  text-decoration: none;
`;
