import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {requestSubmissionList, requestTestResultDetail} from "../../apis";
import classNames from "classnames";
import {IoMdHome} from "react-icons/io";
import {FaCircleCheck} from "react-icons/fa6";
import {MdCancel} from "react-icons/md";
import {TbReportAnalytics} from "react-icons/tb";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import {AnswerChoice} from "../../components/AnswerChoice";
import {Dialog} from "../../components/Dialog";

export const TestResultDetailPage = () => {
    const {uid} = useParams();
    const [testResult, setTestResult] = useState(null);
    const [submissions, setSubmissions] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (uid)
            (async () =>  {
                const testSheetData = await requestTestResultDetail(uid);
                setTestResult(testSheetData);
                const submissionDataset = await requestSubmissionList(uid);
                setSubmissions(submissionDataset);
            })();
    }, [uid]);

    const config = {
        loader: { load: ["[tex]/html"] },
        tex: {
            packages: { "[+]": ["html"] },
            inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"]
            ],
            displayMath: [
                ["$$", "$$"],
                ["\\[", "\\]"]
            ]
        }
    };

    const Header = (
        <div
            className={classNames("d-flex", "flex-row", "justify-content-space-between", "align-items-center")}
            key={"page_testresultdetail-section_header"}
        >
            <div>
                <h3>TEST RESULT</h3>
                <h5 className={"font-thin mt-1"}>{new Date(testResult?.created_at).toLocaleString()}</h5>
            </div>
            <div className={"d-flex flex-row justify-content-center align-items-center gap-2"}>
                <a className={"btn btn-link"} download={"download"} href={`${process.env.REACT_APP_REPORT_STORAGE_URL}/${new Date(testResult?.created_at ?? new Date()).toISOString().split('T')[0].replaceAll('-','')}/${testResult?.uid}.pdf`} target={"_blank"} rel="noreferrer">
                    <button className={"btn btn-info d-flex flex-column"}>
                        <TbReportAnalytics size={24}/>
                        <small>Download Report</small>
                    </button>
                </a>
                <button
                    className={classNames("d-flex", "flex-column", "justify-content-center", "align-items-center", "btn", "btn-primary")}
                    onClick={() => navigate("/")}
                >
                    <IoMdHome size={24}/>
                    <small>Home</small>
                </button>
            </div>
        </div>
    );

    const SummaryView = (
        <div className={"d-flex flex-column gap-1 pt-5"} key={"page_testresultdetail-section_summaryview"}>
            <div className={"d-flex flex-row justify-content-start align-items-center gap-3"}>
                <h4 className={"text-info"}>TEST NAME</h4>
                <p className={"font-thin"}>{testResult?.test_set_version.test_set.name}</p>
            </div>
            <div className={"d-flex flex-row justify-content-start align-items-center gap-3"}>
                <h4 className={"text-info"}>TIMESTAMP</h4>
                <p className={"font-thin"}>{new Date(testResult?.created_at).toLocaleString()}</p>
            </div>
            <div className={"d-flex flex-row justify-content-start align-items-start gap-3 pt-3"}>
                <h4 className={"text-info"}>SCORES</h4>
                <div>
                    {testResult?.scores.map(score => (
                        <p className={"font-thin"}>{score.name} : {score.section_score >= 200 ? score.section_score : 200}</p>
                    ))}
                </div>
            </div>
        </div>
    );

    const SubmissionList = (
        <div className={"pt-5"} key={"page_testresultdetail-section_submissionlist"}>
            <h4 className={"text-info"}>SUBMISSIONS</h4>
            {submissions.map((submission, idx) => (
                <div className={classNames({"pt-5": idx !== 0})} key={submission.uid}>
                    <h4 className={"font-thin"}>SECTION {submission.module.section.seq}, MODULE {submission.module.seq} : <span className={"font-500"}>{submission.module.section.name}</span>({submission.module.module_type_text})</h4>
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>CORRECT ANSWER</th>
                            <th>USER RESPONSE</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {submission.responses.map(response => (
                            <tr key={response.uid}
                                onClick={() => {
                                    setOpen(true);
                                    setCurrentItem({
                                        q: response.question_set,
                                        s: response.response,
                                    });
                                }}>
                                <td className={"font-thin"}>{response.question_set.seq}</td>
                                <td className={"font-thin "}>{response.question_set.answer.toUpperCase().replaceAll(",", " or ")}</td>
                                <td className={"font-thin"}>{response.response ? response.response.toUpperCase() : "-"}</td>
                                <td className={"d-flex justify-content-center align-items-center"}>
                                    {response.question_set.answer.toUpperCase().split(",").includes(response.response.toUpperCase()) ? (
                                        <FaCircleCheck className={"text-success"}/>
                                    ) : (
                                        <MdCancel className={"text-danger"}/>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );

    const QuestionDialog = (
        <Dialog open={open} setOpen={setOpen}>
            <MathJaxContext version={3} config={config}>
                <MathJax inline dynamic>
                    <div id={"dialog-content"} style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 38, height: "100%" }}>
                        <div style={{ backgroundColor: "#FFFFFF", padding: 38, zIndex: 999, borderRadius: 8 }}>
                            <h3>VIEW QUESTION</h3>
                            <div className={"mt-5"} style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                            <div style={{ minWidth: 400 }}>
                                <p className={"text-info"}>QUESTION SEQ</p>
                                <p className={"font-500"}>#{currentItem?.q.seq}</p>
                                <p className={"text-info pt-5"}>PARAGRAPH</p>
                                {currentItem?.q.paragraph && (
                                    <div className={"pt-3"}>
                                        <div dangerouslySetInnerHTML={{ __html: currentItem?.q.paragraph}}/>
                                    </div>
                                )}
                            </div>
                            <div  style={{ minWidth: 400 }}>
                                <p className={"text-info"}>QUESTION</p>
                                <div className={"pt-3"}>
                                    <MathJax inline dynamic>
                                        <div dangerouslySetInnerHTML={{ __html: currentItem?.q.question }}/>
                                    </MathJax>
                                </div>
                                <p className={"text-info pt-5"}>QUESTION TYPE</p>
                                <p className={"pt-1 font-thin"}>{currentItem?.q.solving_type === 0 ? "CHOICES" : "INPUT"}</p>
                                {currentItem?.q.solving_type === 0 ? (
                                    <div style={{ display: "flex", flexDirection: "column", gap: 18 }}>
                                        <p className={"text-info pt-5"}>CHOICES</p>
                                        {currentItem?.q.answer_choices.map((choice) => (
                                            <AnswerChoice
                                                seq={choice.seq}
                                                content={choice.content}
                                                selected={choice.seq.toLowerCase() === currentItem?.q.answer.toLowerCase()}
                                                viewOnly={true}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <div className={"pt-5"}>
                                        <p className={"text-info"}>CORRECT ANSWER</p>
                                        <div className={"pt-3"}>
                                            <div dangerouslySetInnerHTML={{ __html: currentItem?.q.answer }}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </MathJax>
            </MathJaxContext>
        </Dialog>
    );

    return [Header, SummaryView, SubmissionList, QuestionDialog];
}
