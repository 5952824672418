import {useContext, useEffect, useRef} from "react";
import styled from "styled-components";
import {MathJaxBaseContext} from "better-react-mathjax";
import {Body2} from "styles/texts";
import {AnswerChoice} from "components/AnswerChoice";
import MarkerImage from "assets/marker.svg";
import MarkerOnImage from "assets/marker_on.svg";
import * as colors from "styles/colors";
import "assets/styles/table-question.css";
import {useTest} from "hooks";

export function QuestionSolve() {
    const { questionSets, currentQuestionSetIdx, reviews, responses, strikethroughs, markQuestionSetForReview, answerQuestion, strikethroughAnswerChoice } = useTest();
    const renderBlock = useRef(null);
    const mathJaxContext = useContext(MathJaxBaseContext);
    const uid = questionSets.getIn([currentQuestionSetIdx, "uid"]);
    const seq = questionSets.getIn([currentQuestionSetIdx, "seq"]);
    const paragraph = questionSets.getIn([currentQuestionSetIdx, "paragraph"]);
    const question = questionSets.getIn([currentQuestionSetIdx, "question"]);
    const solving_type = questionSets.getIn([currentQuestionSetIdx, "solving_type"]);
    const answer_choices = questionSets.getIn([currentQuestionSetIdx, "answer_choices"]);
    const isReviewMarked = reviews.get(uid);

    useEffect(() => {
        if (mathJaxContext && renderBlock.current) {
            mathJaxContext.promise.then((mathJax) => {
                mathJax.startup.promise.then(() => {
                    console.debug("Clear mathJax Memory");
                    mathJax.typesetClear([renderBlock.current]);
                    mathJax.typesetPromise([renderBlock.current]);
                });
            });
        }
    });

    return (
        <Wrapper ref={renderBlock}>
            {paragraph && (
                <>
                    <InnerWrapper>
                        <ParagraphWrapper dangerouslySetInnerHTML={{ __html: paragraph }}></ParagraphWrapper>
                    </InnerWrapper>
                    <Divider />
                </>
            )}
            <InnerWrapper>
                <SolveWrapper>
                    <QuestionHeader>
                        <QuestionNumber>{seq}</QuestionNumber>
                        <MarkerWrapper>Mark for Review<Marker onClick={function () {
                            markQuestionSetForReview(uid, !isReviewMarked);
                        }} src={isReviewMarked ? MarkerOnImage : MarkerImage} /></MarkerWrapper>
                    </QuestionHeader>
                    <QuestionWrapper dangerouslySetInnerHTML={{ __html: question }}></QuestionWrapper>
                    <AnswerWrapper>
                        {solving_type === 0 && answer_choices.map((choice) => (
                            <AnswerChoice
                                key={`${seq}_${choice.get("seq")}`}
                                seq={choice.get("seq")}
                                content={choice.get("content")}
                                selected={choice.get("seq") === responses.get(uid)}
                                strikethrough={strikethroughs.get(choice.get("uid"))}
                                setSelected={() => {
                                    if (strikethroughs.get(choice.get("uid")))
                                        strikethroughAnswerChoice(choice.get("uid"), false);
                                    if (responses.get(uid) === choice.get("seq"))
                                        answerQuestion(uid, "");
                                    else
                                        answerQuestion(uid, choice.get("seq"));
                                }}
                                setStrikethrough={() => {
                                    if (responses.get(uid) === choice.get("seq"))
                                        answerQuestion(uid, "");
                                    strikethroughAnswerChoice(choice.get("uid"), !strikethroughs.get(choice.get("uid")));
                                }}
                            />
                        ))}
                        {solving_type === 1 && (
                            <AnswerInputWrapper>
                                <AnswerInput maxLength={5} onChange={(e) => answerQuestion(uid, e.target.value)} value={responses.get(uid)} />
                                <AnswerPreview>{`\\(${responses.get(uid)}\\)`}</AnswerPreview>
                            </AnswerInputWrapper>
                        )}
                    </AnswerWrapper>
                </SolveWrapper>
            </InnerWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 24px 40px;
  overflow-y: hidden;
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  overflow-y: scroll;
  padding: 15px;
`;

const ParagraphWrapper = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  width: 8px;
  background-color: ${colors.grayColor};
  height: 100%;
`;

const SolveWrapper = styled.div`
  max-width: 555px;
  width: 100%;
`;

const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const QuestionWrapper = styled.div`
  margin-top: 15px;
`;

const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 30px;
`;

const QuestionNumber = styled(Body2)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: black;
  padding: 5px 9px;
`;

const MarkerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;


const Marker = styled.img``;

const AnswerInputWrapper = styled.div``;

const AnswerInput = styled.input`
  border: 1px solid ${colors.grayColor};
  padding: 15px;
  border-radius: 8px;
  max-width: 148px;
`;

const AnswerPreview = styled.div`
  margin-top: 18px;
`;
