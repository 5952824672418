import {useNavigate, useParams} from "react-router-dom";
import {fromJS} from "immutable";
import styled from "styled-components";
import { Wrapper } from "styles/layouts";
import { Title } from "styles/texts";
import LogoImage from "assets/logo.png";
import {useEffect, useState} from "react";
import {requestTestInformation, requestTestModuleSubmit} from "apis";
import {useTest} from "hooks";

export function TestLoading() {
    const {uid} = useParams();
    const { module, responses, setTestSheet, loadCurrentModule, submit } = useTest();
    const [testSetName, setTestSetName] = useState("");

    useEffect(function () {
        if (uid !== undefined) {
            (async function() {
                if (submit) {
                    await requestTestModuleSubmit(uid, module.get("uid"), responses);
                }
                const data = await requestTestInformation(uid);
                const testSheet = fromJS(data);
                setTestSheet(testSheet);
                setTestSetName(testSheet?.getIn(["test_set_version", "test_set", "name"]));
                await loadCurrentModule(testSheet);
            })();
        }
    }, [uid]);

    return (
        <Wrapper>
            <InnerWrapper>
                <Logo src={LogoImage} />
                <Title>Loading</Title>
                <Title>{testSetName}</Title>
            </InnerWrapper>
        </Wrapper>
    )
}


const InnerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 240px;
`;

