import {Outlet} from "react-router-dom";
import {TestProvider, TimerProvider} from "hooks";

export function TestContainer() {
    return (
        <TimerProvider>
            <TestProvider>
                <Outlet/>
            </TestProvider>
        </TimerProvider>
    )
}
