import {useState} from "react";
import styled from "styled-components";
import {Title} from "styles/texts";
import { useTimer } from "hooks";

export function Timer({...rest}) {
    const { milliSeconds } = useTimer();
    const [hide, setHide] = useState(false);

    function displayTime() {
        if (milliSeconds > 0) {
            const tempTime = new Date();
            const minutes = Math.floor(milliSeconds / 60000).toString();
            const seconds = Math.floor(milliSeconds % 60000 / 1000).toString();
            const minutesString = minutes.length === 2 ? minutes : "0".concat(minutes);
            const secondsString = seconds.length === 2 ? seconds : "0".concat(seconds);
            return `${minutesString}:${secondsString}`;
        } else {
            return "00:00"
        }
    }

    return (
        <Wrapper>
            <Time {...rest}>{!hide ? displayTime() : "Timer Hidden"}</Time>
            <HideTimerButton onClick={() => setHide(!hide)}>{hide ? "Show" : "Hide"}</HideTimerButton>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  text-align: center;
`;

const Time = styled(Title)`
  color: inherit;
`;

const HideTimerButton = styled.button`
  border: 2px solid black;
  background-color: transparent;
  border-radius: 18px;
  padding: 2px 14px;
  cursor: pointer;
  transition-duration: 0.3s;
  margin-top: 8px;
  font-size: 12px;
  
  :hover {
    color: white;
    background-color: black;
  }
`;
