import classNames from "classnames";
import {IoMdHome} from "react-icons/io";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {SiTestrail} from "react-icons/si";
import {GrView} from "react-icons/gr";
import {requestTestResults} from "../../apis/test_sheet";

export const TestResultListPage = () => {
    const [testResults, setTestResults] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const dataset = await requestTestResults();
            setTestResults(dataset);
        })();
    }, []);

    const Header = (
        <div
            className={classNames("d-flex", "flex-row", "justify-content-space-between", "align-items-center")}
            key={"page_testresultlist-section_header"}
        >
            <div>
                <h3>TEST RESULTS</h3>
                <h5 className={"font-thin mt-1"}>Total {testResults.length}</h5>
            </div>
            <button
                className={classNames("d-flex", "flex-column", "justify-content-center", "align-items-center", "btn", "btn-primary")}
                onClick={() => navigate("/")}
            >
                <IoMdHome size={24}/>
                <small>Home</small>
            </button>
        </div>
    );

    const TestResultList = (
        <ul className={"pt-5"} key={"page-testresultlist_section-testresultlist"} key={"page_testresultlist-section_testresultlist"}>
            {testResults.map((testResult) => (
                <li className={classNames("d-flex", "flex-row", "justify-content-space-between", "p-3")} key={testResult.uid}>
                    <div className={classNames("d-flex", "flex-row", "align-items-center", "gap-3")}>
                        <SiTestrail/>
                        <div>
                            <h4>{testResult.test_set_version.test_set.name}</h4>
                            <small className={"font-thin"}>{new Date(testResult.created_at).toLocaleString()}</small>
                        </div>
                    </div>
                    <div className={classNames("d-flex", "flex-row", "gap-3")}>
                        <ul className={"d-flex flex-column justify-content-center align-items-center"}>
                            {testResult.scores.map(score => (
                                <li key={`${testResult.uid}_${score.name}`}>
                                    <p>{score.name} : {score.section_score >= 200 ? score.section_score : 200}</p>
                                </li>
                            ))}
                        </ul>
                        <div className={"d-flex justify-content-center align-items-center"}>
                            <button className={classNames("btn", "btn-primary")} onClick={() => navigate(`/results/${testResult.uid}`)}>
                                <GrView/>
                                <small className={"font-thin"}>VIEW</small>
                            </button>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );

    return [Header, TestResultList];
}
