import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useAuth} from "hooks";
import LogoImage from "assets/full_logo.png";
import StartANewTestSvg from "assets/start_a_new_test.svg";
import TestResultSvg from "assets/test_result.svg";
import HelloImage from "assets/hello.png";
import MainImage from "assets/main_image.svg";
import {FaSignOutAlt} from "react-icons/fa";
import {useEffect, useState} from "react";
import {requestTestSheetDelete, requestTestSheets} from "apis";
import {fromJS} from "immutable";
import {requestStartUpInfo} from "../apis/setup";

export const MainPage = () => {
    const [testSheets, setTestSheets] = useState([]);
    const navigate = useNavigate();
    const {user, logout} = useAuth();
    const [basicInfo, setBasicInfo] = useState(null);
    const {confirm} = window;

    useEffect(() => {
        (async function () {
            const response = await requestTestSheets();
            setTestSheets(response);
            const info = await requestStartUpInfo(window.location.host.split(".")[0]);
            setBasicInfo(info);
        })();
    }, []);

    const Logo = () => {
        if (basicInfo !== null)
            return <img src={basicInfo.logo} width={280} alt={"Logo"}/>
        else
            return <img src={LogoImage} width={280} alt={"Test Qube Logo"}/>;
    }

    const Nav = (
        <nav className={classNames("d-flex", "flex-row", "justify-content-space-between", "align-items-stat", "pb-5")} key={"page_main-section_nav"}>
            <Logo/>
            <div className={"d-flex flex-row align-items-center gap-2"}>
                {(user.is_director || user.is_manager) && (
                    <a style={{ textDecoration: "none" }} href={process.env.REACT_APP_CLASSROOM_URL} target={"_blank"} rel={"noreferrer"}>
                        <button className={classNames("btn", "btn-primary")}>
                            <FaSignOutAlt/>
                            <small>Classroom</small>
                        </button>
                    </a>
                )}
                <button className={classNames("btn", "btn-danger")} onClick={logout}>
                    <FaSignOutAlt/>
                    <small>Sign Out</small>
                </button>
            </div>
        </nav>
    );

    const Header = (
        <div className={classNames("d-flex", "flex-row", "justify-content-space-between", "align-items-center", "pt-5")} key={"page_main-section_header"}>
            <div>
                <h2 className={classNames("font-500")}>What's up, {user.first_name} {user.last_name}! <img src={HelloImage} alt={"Waving Hand"} /></h2>
                <h3 className={classNames("font-thin")}>Dive into a practice test and gear up<br/>for the big showdown on test day!</h3>
            </div>
            <div className={classNames("pr-5")}>
                <img src={MainImage} alt={"Main Hero"}/>
            </div>
        </div>
    );

    const TopDivider = (
        <div className={classNames("divider", "bg-gray", "my-5")} key={"page_main-section_topdivider"} />
    );

    const BottomDivider = (
        <div className={classNames("divider", "bg-gray", "my-5")} key={"page_main-section_bottomdivider"} />
    );

    const MainMenu = (
        <div className={"d-flex flex-row gap-3"} key={"page_main-section_mainmenu"}>
            <button className={"btn d-flex"} onClick={() => navigate("/tests")}>
                <img className={"h-100"} src={StartANewTestSvg} alt={"Start A New Test Button"} />
            </button>
            <div className={"btn d-flex"} onClick={() => navigate("/results")}>
                <img src={TestResultSvg} alt={"Test Results"} />
            </div>
        </div>
    );

    const RunningTestList = (
        <div key={"page_main-section_runningtestlist"}>
            <h3>TEST IN PROGRESS</h3>
            <div className={"d-flex flex-row gap-3 mt-3"} style={{ flexWrap: "wrap" }}>
                {testSheets.map(testSheet => (
                    <div className={"px-5 py-3 text-center"} style={{ border: "1px solid #DFDFDF", borderRadius: 8, boxShadow: "5px 7px 17px rgba(0, 0, 0, 0.24)" }} key={testSheet.uid}>
                        <h3>{testSheet.test_set_version.test_set.name}</h3>
                        <small>{new Date(testSheet.created_at).toDateString()}</small>
                        <div className={"d-flex flex-row gap-2 mt-3 justify-content-center align-items-center"}>
                            <button className={"text-white bg-success"} onClick={() => navigate(`/test_center/tests/${testSheet.uid}/loading`, {replace: true})}>Resume</button>
                            <button className={"text-white bg-danger"} onClick={() => {
                                const ask = confirm("Are you sure to delete running test?");
                                if (ask) {
                                    (async function () {
                                        await requestTestSheetDelete(testSheet.uid);
                                        const response = await requestTestSheets();
                                        setTestSheets(response);
                                        alert("Deleted successfully");
                                    })();
                                }
                            }}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return [Nav, Header, TopDivider, MainMenu, BottomDivider, RunningTestList];
}
