import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {VscDebugStart} from "react-icons/vsc";
import {IoMdHome} from "react-icons/io";
import {LiaClipboardListSolid} from "react-icons/lia";
import {requestTestSets} from "apis";
import {requestTestSheetCreate} from "../../apis/test_sheet";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import {Box, Button, FormLabel, Option, Select, Typography} from "@mui/joy";

export const TestStoragePage = () => {
    const [testSets, setTestSets] = useState([]);
    const [selectedTest, setSelectedTest] = useState({});
    const [timerSetting, setTimerSetting] = useState(1);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const dataset = await requestTestSets();
            setTestSets(dataset);
        })();
    }, []);

    const setupTest = (testSet) => {
        setSelectedTest(testSet);
        setOpen(true);
    }

    const startTest = async (testSetUid) => {
        const test_sheet = await requestTestSheetCreate(testSetUid);
        window.localStorage.setItem(`${test_sheet.uid}_timer`, timerSetting.toString());
        navigate(`/test_center/tests/${test_sheet.uid}/loading`, { replace: true });
    }

    const StartUpModal = (
        <Modal open={open} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClose={() => {
            setOpen(false);
            setSelectedTest({});
            setTimerSetting(1);
        }}>
            <ModalDialog>
                <Typography level="h3">Before you start</Typography>
                <Box sx={{ maxWidth: 480 }}>
                    <Typography level="title-lg" gutterBottom="10">Timing</Typography>
                    <Typography>This full-length practice test is timed like the real SAT, but you can pause any time by selecting <b>Save and Exit</b> from the <b>More</b> menu. You can also move from one section to the next before time expires.</Typography>
                </Box>
                <Box sx={{ maxWidth: 480 }}>
                    <Typography level="title-lg" gutterBottom="10">Scores</Typography>
                    <Typography>When you finish the practice test, go to <b>Test Results</b> to see your scores and get personalized study tips.</Typography>
                </Box>
                <Box sx={{ maxWidth: 480 }}>
                    <Typography level="title-lg" gutterBottom="10">Assistive Technology</Typography>
                    <Typography>If you use assistive technology, you should try it out on the practice test so you know what to expect on test day.</Typography>
                </Box>
                <Box sx={{ marginTop: 4 }}>
                    <FormLabel>Selected Test</FormLabel>
                    <Typography level="title-lg">{selectedTest.name}</Typography>
                </Box>
                <Box>
                    <FormLabel>Timer Setting</FormLabel>
                    <Select value={timerSetting} onChange={(e, v) => setTimerSetting(v)}>
                        <Option value={1}>Normal</Option>
                        <Option value={0.5}>One-half</Option>
                        <Option value={2}>Double</Option>
                        <Option value={-1}>Untimed</Option>
                    </Select>
                </Box>
                <Button sx={{ marginTop: 2 }} onClick={() => startTest(selectedTest.uid)}>Start Test</Button>
                <Button color="danger" onClick={() => {
                    setOpen(false);
                    setSelectedTest({});
                    setTimerSetting(1);
                }}>Cancel</Button>
            </ModalDialog>
        </Modal>
    );

    const Header = (
        <div className={classNames("d-flex", "flex-row", "justify-content-space-between", "align-items-center")} key={"page_teststorage-section_header"}>
            <div>
                <h3>TEST STORAGES</h3>
                <h5 className={"font-thin mt-1"}>Total {testSets.length}</h5>
            </div>
            <button
                className={classNames("d-flex", "flex-column", "justify-content-center", "align-items-center", "btn", "btn-primary")}
                onClick={() => navigate("/")}
            >
                <IoMdHome size={24}/>
                <small>Home</small>
            </button>
        </div>
    );

    const TestSetList = (
        <ul className={"pt-5"} key={"page-teststorage-section_testsetlist"}>
            {testSets.map((testSet) => (
                <li className={classNames("d-flex", "flex-row", "justify-content-space-between", "p-3")} key={testSet.uid}>
                    <div className={classNames("d-flex", "flex-row", "align-items-center", "gap-3")}>
                        <LiaClipboardListSolid />
                        <h4>{testSet.name}</h4>
                    </div>
                    <div className={classNames("d-flex", "flex-row", "gap-3")}>
                        <button className={classNames("btn", "btn-primary")} onClick={() => setupTest(testSet)}>
                            <VscDebugStart/>
                            <small className={"font-thin"}>START</small>
                        </button>
                    </div>
                </li>
            ))}
        </ul>
    );

    return [StartUpModal, Header, TestSetList];
}
