import styled from "styled-components";
import {Title} from "styles/texts";
import {useTest} from "hooks";

export function ReviewDialog({sectionSeq, moduleSeq, sectionName, questionSets, navigateTo, setShowReviewDialog, ...rest}) {
    const { reviews, responses } = useTest();
    function checkAnswered(questionSet) {
        return responses.get(questionSet.get("uid")) !== "";
    }

    function checkReview(questionSet) {
        return reviews.get(questionSet.get("uid"));
    }

    return (
        <Wrapper {...rest}>
            <ReviewPanel>
                <PanelHeader>
                    <PanelTitle>Section {sectionSeq}, Module {moduleSeq}: {sectionName}</PanelTitle>
                </PanelHeader>
                <PanelBody>
                    {questionSets.map((questionSet, idx) => (
                        <Question key={`${questionSet.get("seq")}`} onClick={() => {
                            setShowReviewDialog(false);
                            navigateTo(idx);
                        }} className={[checkAnswered(questionSet) && "answered", checkReview(questionSet) && "review"]}>{questionSet.get("seq")}</Question>
                    ))}
                </PanelBody>
                <GoToReviewButton onClick={() => {
                    setShowReviewDialog(false);
                    navigateTo(questionSets.size);
                }}>Go to Review Page</GoToReviewButton>
            </ReviewPanel>
        </Wrapper>
    )

}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;
  width: 100%;
  height: 100%;
  max-width: 480px;
`;

const ReviewPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 5px 7px 17px rgba(0, 0, 0, 0.24);
  border-radius: 14px;
  max-width: 884px;
  width: 100%;
  padding: 15px;
  text-align: center;
`;

const PanelHeader = styled.div`
  border-bottom: 1px solid black;
  width: 100%;
`;

const PanelTitle = styled(Title)`
  padding: 15px;
`;

const PanelBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding: 15px;
`;

const Question = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 14px;
  border: 1px solid black;
  cursor: pointer;
  
  &.answered {
    color: white;
    background-color: black;
  }
  
  &.review {
    color: white;
    background-color: red;
  }
`;

const GoToReviewButton = styled.button`
  border: 2px solid black;
  background-color: transparent;
  border-radius: 18px;
  padding: 6px 14px;
  cursor: pointer;
  transition-duration: 0.3s;
  margin-top: 8px;
  
  :hover {
    color: white;
    background-color: black;
  }
`;
