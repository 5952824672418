import {useState} from "react";
import styled from "styled-components";
import * as colors from "styles/colors";

export function Menu({menuList}) {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <MenuWrapper>
            <MenuButton onClick={() => setShowMenu(!showMenu)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
                    <path d="M24 1L1 0.999998" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round"/>
                    <path d="M24 9L1 9" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round"/>
                    <path d="M24 17L1 17" stroke="#3D3D3D" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </MenuButton>
            {showMenu && (
                <MenuList>
                    {menuList.map((menuItem) => (
                        <MenuItem onClick={menuItem.onClick}>{menuItem.title}</MenuItem>
                    ))}
                </MenuList>
            )}
        </MenuWrapper>
    )
}

const MenuWrapper = styled.div`
  position: relative;
  //width: 100%;
  text-align: end;
`;

const MenuButton = styled.button`
  background-size: contain;
  background-color: transparent;
  border: none;
  width: 32px;
  cursor: pointer;
`;

const MenuList = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border: 1px solid ${colors.grayColor};
  border-radius: 8px;
  margin-top: 32px;
`;

const MenuItem = styled.button`
  padding: 8px 18px;
  cursor: pointer;
  border: none;
  background-color: white;
  word-break: keep-all;
  white-space: nowrap;
  display: block;
  
  :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
