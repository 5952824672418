import styled from "styled-components";
import {Button} from "styles/buttons";

export function Dialog({ open, setOpen, children }) {

    return (
        <AbsoluteWrapper className={[open && "open"]}>
            <Background onClick={() => setOpen(false)} />
            <ContentWrapper>
                <ChildrenWrapper>{children}</ChildrenWrapper>
                <ActionWrapper>
                    <CloseButton onClick={() => setOpen(false)}>Close</CloseButton>
                </ActionWrapper>
            </ContentWrapper>
        </AbsoluteWrapper>
    )
}

const AbsoluteWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  
  &.open {
    display: block;
  }
`;

const Background = styled.div`
  background-color: #8F8F8F80;
  position: fixed;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  left: 0;
  right: 0;
`;

const ChildrenWrapper = styled.div``;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 18px;
  margin-bottom: 18px;
`;

const CloseButton = styled(Button)`
  color: white;
  background-color: #707BFC;
  border-radius: 38px;
  border: none;
  padding: 8px 28px;
  cursor: pointer;
  z-index: 888;
`;
